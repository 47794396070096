import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue } from 'antd/lib/table/interface';
import {
    useContext, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import moment, { isMoment } from 'moment';
import { IListFilter, IListResponse } from '../types/list';
import { IOrder } from '../types/order';
import { useFetch } from './useFetch';
import { TablesContext } from '../context/tables';

interface IUseOrdersListOptions {
    search?: string;
    userId?: number;
}

const defaultOptions:IUseOrdersListOptions = {};

export const useOrdersList = ({ search, userId } = defaultOptions) => {
    const fetch = useFetch();
    const [data, setData] = useState<IListResponse<IOrder>>();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    const tableName = typeof userId === 'undefined' ? 'orders' : `user-${userId}-orders`;

    const { tables, functions } = useContext(TablesContext);
    const { saveFilters } = functions;

    const filters: IListFilter[] | undefined = tables[tableName]?.filters;

    const parseFilters = () => (Array.isArray(filters) ? Array(...filters).map((filter) => {
        const x = { ...filter };
        if (x.column === 'created_at' && x.values?.length) {
            x.operator = 'between';
            x.values = x.values?.map((el) => {
                if (isMoment(el)) {
                    return moment(el).format('YYYY-MM-DD');
                }
                return el;
            });
            return x;
        }
        if (['id', 'glsTrackingCode.tracking_id', 'sender.name', 'recipient.name', 'creator.name'].includes(x.column)) {
            x.operator = 'contains';
        }
        if (x.column === 'deliveryTypeName') {
            x.column = 'delivery_type_id';
        }
        return x;
    }) : []);

    const getData = async () => {
        let url = typeof userId === 'number' ? `/clients/${userId}/orders` : '/orders';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await fetch.request({
            url,
            method: 'post',
            data: {
                count: 30,
                sorters: [{ column: 'id', desc: true }],
                filters: parseFilters(),
                search: typeof search === 'string' && search.length > 0 ? search : undefined,
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    const onTableChange = (pagination: TablePaginationConfig, f: Record<string, FilterValue | null>) => {
        const keys = Object.keys(f);
        const nextState: IListFilter[] = [];
        keys.forEach((column) => {
            const values = f[column];
            if (Array.isArray(values)) {
                nextState.push({ column, values });
            }
        });
        saveFilters(tableName, nextState);
    };

    useEffect(() => {
        getData();
    }, [page, filters, search]);

    return {
        data,
        filters,
        loading: fetch.loading,
        onTableChange,
        update: getData,
    };
};
