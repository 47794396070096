import { notification } from 'antd';
import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/user';
import { IUserSettings } from '../types/user';
import { useApp } from './useApp';
import { useFetch } from './useFetch';

export interface IUserInfo {
    id: number;
    name: string;
    surname: string;
    phone: string;
    email: string;
    email_verified_at: string | null;
    role: string;
    created_at: string;
    updated_at: string;
    photo: string;
    settings: IUserSettings | null;
}

interface IUpdateInfoBody {
    name?: string;
    surname?: string;
    phone?: string;
    email?: string;
}

interface IUseUserParams {
    canFetch: boolean;
    canLogoutBy401: boolean;
    showFetchMessages: boolean;
}

const defaultParams: IUseUserParams = {
    canFetch: true,
    canLogoutBy401: true,
    showFetchMessages: true,
};

export const useUser = ({ canFetch, canLogoutBy401, showFetchMessages } = defaultParams) => {
    const app = useApp();
    const { setInfo, info } = useContext(UserContext);
    const fetch = useFetch({ showErrorMessage: showFetchMessages });
    const uploadFetch = useFetch();
    const updateFetch = useFetch();
    const { t } = useTranslation();

    const getUserInfo = async () => {
        const result = await fetch.request({
            url: '/user/info',
        });
        if (result.success) {
            setInfo(result.data);
        } else if (result.status === 401 || result.status === 404 || canLogoutBy401) {
            app.logout();
        }
    };

    const uploadPhoto = async (photo: Blob) => {
        const formData = new FormData();
        formData.append('photo', photo);
        const result = await uploadFetch.request({
            url: '/user/upload/photo',
            data: formData,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (result.success) {
            getUserInfo();
            notification.success({
                message: t('userPhotoUpdatedMessage'),
            });
        }
    };

    const update = async (values: IUpdateInfoBody) => {
        const data: IUpdateInfoBody = {};
        const keys = Object.keys(values) as (keyof IUpdateInfoBody)[];
        keys.forEach((key) => {
            if (values[key] !== info?.[key]) {
                data[key] = values[key];
            }
        });
        const result = await updateFetch.request({
            url: '/user/update',
            method: 'post',
            data,
        });
        if (result.success) {
            getUserInfo();
            notification.success({
                message: t('userInfoUpdatedMessage'),
            });
        }
    };

    useEffect(() => {
        if (typeof info === 'undefined' && canFetch) {
            getUserInfo();
        }
    }, [canFetch]);

    return {
        info,
        loading: fetch.loading,
        update: {
            request: update,
            loading: updateFetch.loading,
        },
        uploadPhoto: {
            request: uploadPhoto,
            loading: uploadFetch.loading,
        },
    };
};
