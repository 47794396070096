import { Button, Card } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useServiceLogs } from '../hooks/useServiceLogs';
import { IServiceLog } from '../types/service-log';
import Table from '../components/table';

function LogsPage() {
    const { loading, logs } = useServiceLogs();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onNextClick = (row: IServiceLog) => {
        let route = '';
        if (row.order_id) {
            route = `/order/${row.order_id}`;
        }
        if (row.user_id) {
            route = `/client/${row.user_id}`;
        }
        if (row.transaction_id) {
            route = `/finance/${row.transaction_id}`;
        }
        navigate(route);
    };

    return (
        <Card title={t('serviceLogs')}>
            <Table
                loading={loading}
                columns={[
                    {
                        dataIndex: 'id',
                        title: '#',
                    },
                    {
                        dataIndex: 'typeLabel',
                        title: t('serviceLogsType'),
                    },
                    {
                        dataIndex: 'creator',
                        title: t('user'),
                        render: (_, row: IServiceLog) => `${row.creator.surname} ${row.creator.name}`,
                    },
                    {
                        dataIndex: 'crated_at',
                        title: t('time'),
                        render: (_, row: IServiceLog) => moment(row.created_at).format('DD.MM.YYYY HH:mm'),
                    },
                    {
                        key: 'actions',
                        render: (_, row: IServiceLog) => (
                            <Button
                                icon={<RightCircleOutlined color="primary" />}
                                type="primary"
                                onClick={() => onNextClick(row)}
                            />
                        ),
                    },
                ]}
                dataSource={logs?.list}
                pagination={{
                    current: logs?.currentPage,
                    total: logs?.total,
                }}
            />
        </Card>
    );
}

export default LogsPage;
